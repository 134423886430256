import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';

interface AnimatedTitleProps {
  children: ReactNode;
  className?: string;
}

export function AnimatedTitle({ children, className = '' }: AnimatedTitleProps) {
  return (
    <motion.h2 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-16 text-indigo-900 ${className}`}
    >
      {children}
    </motion.h2>
  );
}